import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { GeneralInfoForm } from "../components/Forms";

import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import {Game} from "../components/Game";
import {SliderShow} from "../components/SliderShow";


export default () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-flex">
                </div>
            </div>

            <Row>
                <Col xs={12} xl={8}>
                    <Game />
                </Col>

                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <SliderShow />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
