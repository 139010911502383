import React, { useEffect, useState, useCallback } from "react";
import {
    Table, Card, Button, Pagination, Spinner, Alert, Badge, Modal
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faPlay, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import url from "../config";

const GamesTable = () => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [alerts, setAlerts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [winnerData, setWinnerData] = useState(null);
    const [loadingWinner, setLoadingWinner] = useState(false);
    const [activeGameId, setActiveGameId] = useState(null);

    const gamesPerPage = 10;

    const formatDate = (dateStr) => {
        if (!dateStr) return "N/A";
        const [day, month, year] = dateStr.split("-");
        return new Date(year, month - 1, day).toLocaleDateString("fr-FR");
    };

    const addAlert = useCallback((message, variant, duration = 5000) => {
        const id = Date.now();
        setAlerts(prevAlerts => [...prevAlerts, { id, message, variant }]);
        setTimeout(() => removeAlert(id), duration);
    }, []);

    const removeAlert = useCallback((id) => {
        setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
    }, []);

    useEffect(() => {
        const fetchGames = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");

            if (!token) {
                addAlert("Veuillez vous connecter pour accéder aux données des jeux concours.", "danger");
                setLoading(false);
                return;
            }

            try {
                const response = await axios.get(`${url}/api/admin/getAllGames`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setGames(response.data || []);
                const activeGame = response.data.find(game => game.status);
                setActiveGameId(activeGame ? activeGame.id : null);
            } catch (error) {
                console.error("Erreur lors du chargement des jeux concours:", error);
                const message = error.response?.data?.message || "Erreur lors du chargement des jeux concours.";
                addAlert(message, "danger");
            } finally {
                setLoading(false);
            }
        };

        fetchGames();
    }, [addAlert]);

    const handleGetGrandLotWinner = async (gameId) => {
        setLoadingWinner(true);
        setShowModal(true);
        try {
            const response = await axios.get(`${url}/api/admin/jackpotWinner/${gameId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (response.data.user) {
                setWinnerData(response.data.user);
            } else {
                setWinnerData(null);
                addAlert(response.data.message || "Aucun gagnant trouvé", "info");
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du gagnant:", error);
            const message = error.response?.data?.message || "Erreur lors de la récupération du gagnant.";
            addAlert(message, "danger");
        } finally {
            setLoadingWinner(false);
        }
    };

    const handleActivateGame = async (gameId) => {
        setLoading(true);
        try {
            const response = await axios.post(`${url}/api/admin/jeux_concours/${gameId}/activate`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            addAlert(response.data?.message || "Le jeu concours a été activé avec succès.", "success");
            setGames(games.map(game => game.id === gameId ? { ...game, status: true } : { ...game, status: false }));
            setActiveGameId(gameId);
        } catch (error) {
            console.error("Erreur lors de l'activation du jeu concours:", error);
            const message = error.response?.data?.message || "Erreur lors de l'activation du jeu concours.";
            addAlert(message, "danger");
        } finally {
            setLoading(false);
        }
    };

    const handleDeactivateGame = async (gameId) => {
        setLoading(true);
        try {
            const response = await axios.post(`${url}/api/admin/jeux_concours/${gameId}/deactivate`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            addAlert(response.data?.message || "Le jeu concours a été désactivé avec succès.", "success");
            setGames(games.map(game => game.id === gameId ? { ...game, status: false } : game));
            setActiveGameId(null);
        } catch (error) {
            console.error("Erreur lors de la désactivation du jeu concours:", error);
            const message = error.response?.data?.message || "Erreur lors de la désactivation du jeu concours.";
            addAlert(message, "danger");
        } finally {
            setLoading(false);
        }
    };

    const TableRow = ({ game }) => {
        return (
            <tr>
                <td>{game.intitule || "N/A"}</td>
                <td>{formatDate(game.dateDeDebut)}</td>
                <td>{formatDate(game.dateDeFin)}</td>
                <td>{game.duree || "N/A"}</td>
                <td>{game.nombreDeTicket || "N/A"}</td>
                <td>{formatDate(game.dateDeFinDuJeux)}</td>
                <td>
                    {game.status ? (
                        <Badge bg="success">En cours</Badge>
                    ) : (
                        <Badge bg="secondary">Non actif</Badge>
                    )}
                </td>
                <td>
                    {game.jackpotEffet ? (
                        <Badge bg="primary">Gagnant du grand lot défini</Badge>
                    ) : (
                        <Badge bg="warning">Gagnant du grand lot non tiré</Badge>
                    )}
                </td>
                <td>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleGetGrandLotWinner(game.id)}
                        className="me-2"
                        disabled={!game.status || game.jackpotEffet}
                    >
                        <FontAwesomeIcon icon={faTrophy} className="me-2" />
                        Gagnant du grand lot
                    </Button>
                    {game.status ? (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeactivateGame(game.id)}
                            className="me-2"
                        >
                            <FontAwesomeIcon icon={faStop} className="me-2" />
                            Désactiver
                        </Button>
                    ) : (
                        <Button
                            variant="outline-success"
                            size="sm"
                            onClick={() => handleActivateGame(game.id)}
                            className="me-2"
                        >
                            <FontAwesomeIcon icon={faPlay} className="me-2" />
                            Activer
                        </Button>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Header>
                <h5>Liste des Jeux Concours</h5>
            </Card.Header>
            <Card.Body className="pt-0">
                {alerts.map(alert => (
                    <Alert key={alert.id} variant={alert.variant} className="mt-2 d-flex justify-content-between align-items-center">
                        {alert.message}
                        <Button variant="link" className="p-0 ms-2" onClick={() => removeAlert(alert.id)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </Alert>
                ))}
                {loading ? (
                    <div className="text-center my-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <Table responsive className="table-centered table-nowrap rounded mb-0">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-0">Intitulé</th>
                            <th className="border-0">Date de Début</th>
                            <th className="border-0">Date de Fin</th>
                            <th className="border-0">Durée (jours)</th>
                            <th className="border-0">Nombre de Tickets</th>
                            <th className="border-0">Date de Fin du Jeu</th>
                            <th className="border-0">Statut</th>
                            <th className="border-0">Jackpot Effet</th>
                            <th className="border-0">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {games.length > 0 ? (
                            games.slice((currentPage - 1) * gamesPerPage, currentPage * gamesPerPage).map((game, index) => <TableRow key={index} game={game} />)
                        ) : (
                            <tr>
                                <td colSpan="9" className="text-center">Aucun jeu concours trouvé.</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                )}

                <Pagination className="mt-3 justify-content-center">
                    {[...Array(Math.ceil(games.length / gamesPerPage)).keys()].map((number) => (
                        <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => setCurrentPage(number + 1)}
                        >
                            {number + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </Card.Body>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Gagnant du Grand Lot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingWinner ? (
                        <div className="text-center my-4">
                            <Spinner animation="border" />
                        </div>
                    ) : winnerData ? (
                        <div>
                            <p><strong>Nom:</strong> {winnerData.nom}</p>
                            <p><strong>Prénom:</strong> {winnerData.prenom}</p>
                            <p><strong>Email:</strong> {winnerData.email}</p>
                            <p><strong>Date de Naissance:</strong> {formatDate(winnerData.dateDeNaissance)}</p>
                            <p><strong>Genre:</strong> {winnerData.genre}</p>
                        </div>
                    ) : (
                        <Alert variant="info">Aucun gagnant trouvé pour ce jeu concours.</Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default GamesTable;
