
export const Routes = {

    // Route for customer
    GameCustomer: {path:"/gameCustomer"},
    ListPrizes: {path:"/listPrizes"},
    ProfilUser: {path:"/profilUser"},
    Logiciel: {path:"/logiciel"},

    // Routes for admin
    ListUser: {path:"/listUser"},
    ListGame: {path:"/listGame"},
    CreateGame: {path: "/CreateGame"},
    DashbordGame: {path: "/dashboard/DashbordGame"},

    // Route for manager
    RedemPrizeUser: {path: "/RedemPrizeUser"},

    // pages
    // Presentation: { path: "/" },
    // DashboardOverview: { path: "/dashboard/overview" },
    // DashboardOverview: { path: "/dashboard/DashboardGame" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    /*Signin: { path: "/examples/sign-in" }, */
    Signin: { path: "/examples/signin" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },
    TermsConditions: { path: "/examples/TermsConditions"},

    Cgu: { path: "/examples/Cgu"},
    Cgv: { path: "/examples/Cgv"},
    Politique: { path: "/examples/Politique"},
    LandingPage: { path: "/"},

    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};