
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import {
  CounterWidget,
  CircleChartWidget,
  BarChartWidget,
  TeamMembersWidget,
  ProgressTrackWidget,
  RankingWidget,
  SalesValueWidget,
  SalesValueWidgetPhone,
  AcquisitionWidget,
  GameStatsWidget
} from "../../components/Widgets";
import { PageVisitsTable } from "../../components/Tables";
import {trafficShares, totalOrders, ticketRewards} from "../../data/charts";

export default () => {
  return (
      <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h4>Tableaux de bord du jeu en cours</h4>
            <p className="mb-0">
              Le tableau de bord présente les statistiques en temps réel du jeu en cours, incluant le nombre de
              participants.
            </p>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <ButtonGroup>
            </ButtonGroup>
          </div>
        </div>
        <Row>
          <Col xs={12} xl={12} className="my-4 ">
            <GameStatsWidget/>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Row>
              <Col xs={12} xl={4}>
                <Row>
                  <Col xs={12} className="mb-4">
                    <BarChartWidget
                        title="Total orders"
                        value={452}
                        percentage={18.2}
                        data={totalOrders}/>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} xl={4}>
                <Row>
                  <Col xs={12} className="mb-4">
                    <BarChartWidget
                        title="Total orders"
                        value={452}
                        percentage={18.2}
                        data={totalOrders}/>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} xl={4}>
                <Row>
                  <Col xs={12} className="mb-4">
                    <CircleChartWidget
                        title="Répartition des gains"/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
  );
};
