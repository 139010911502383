import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Nav, Navbar, Carousel, Card } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faShoppingCart, faLeaf, faUser } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../../routes";
import ReactHero from "../../assets/img/technologies/react-hero-logo.svg";
import moment from "moment-timezone";

const LandingPage = () => {
  const currentYear = moment().get("year");

  return (
      <>
        {/* Navbar */}
        <Navbar expand="lg" variant="dark" className="navbar-custom fixed-top shadow-sm" style={{ background: 'linear-gradient(45deg, #2C3E50, #3498DB)' }}>
          <Container>
            <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
              <img src={ReactHero} alt="Thé Tip Top Logo" style={{ width: '50px', marginRight: '10px'}} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to={Routes.Signup.path} className="btn btn-outline-light me-2">
                  <FontAwesomeIcon icon={faUser} className="me-2" /> S'inscrire
                </Nav.Link>
                <Nav.Link as={Link} to={Routes.Signin.path} className="btn btn-warning">
                  <FontAwesomeIcon icon={faUser} className="me-2" /> Se connecter
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Header */}
        <header className="masthead text-center text-white" style={{
          background: 'linear-gradient(45deg, #2C3E50, #3498DB)',
          paddingTop: '120px',
          paddingBottom: '120px',
          clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)'
        }}>
          <Container>
            <h1 className="masthead-heading mb-4" style={{ fontSize: '3.5rem', fontWeight: '700' }}>
              Thé Tip Top fête sa 10ème boutique !
            </h1>
            <h2 className="masthead-subheading mb-4" style={{ fontSize: '1.75rem', fontWeight: '300', color: '#F39C12' }}>
              Rejoignez notre célébration et participez à un événement exceptionnel !
            </h2>
            <p className="lead mx-auto" style={{ maxWidth: '700px', color: '#ECF0F1' }}>
              Depuis nos débuts, nous nous sommes engagés à offrir des thés bio et artisanaux de la plus haute qualité. Découvrez comment vous pouvez gagner des prix incroyables !
            </p>
            <Button
                variant="warning"
                size="lg"
                className="rounded-pill mt-4 animate__animated animate__pulse animate__infinite"
                as={Link}
                to={Routes.Signin.path}
                style={{ boxShadow: '0 4px 15px rgba(0,0,0,0.2)' }}
            >
              Participer maintenant
            </Button>
          </Container>
        </header>

        {/* About Section */}
        <section id="about" className="py-5" style={{ backgroundColor: '#F7F9FA' }}>
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="mb-4 mb-lg-0">
                <img className="img-fluid rounded-circle shadow"
                    src={require('../../assets/img/tea/tea1.jpg')}
                    alt="Tasse de thé" />
              </Col>
              <Col lg={6}>
                <h2 className="display-4 mb-4">Pour ceux qui aiment le thé...</h2>
                <p className="lead">
                  Chez Thé Tip Top, nous croyons en l'importance de la qualité et de l'authenticité. Nos thés sont soigneusement sélectionnés pour leur saveur riche et leurs bienfaits sur la santé.
                </p>
                <p>
                  Pour chaque achat de 49€ ou plus, recevez un code unique qui vous permettra de participer à notre tirage au sort. 100% des tickets sont gagnants !
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Prizes Section with Slider */}
        <section id="prizes" className="py-5" style={{ background: '#F0F3F4' }}>
        <Container>
      <h2 className="display-4 text-center mb-5">Découvrez les lots à gagner</h2>

      <div className="coffee_section_2">
        <div id="main_slider" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="coffee_img">
                    </div>
                    <h3 className="types_text">Infuseur de thé</h3>
                    <p className="looking_text">
                      Découvrez notre infuseur de thé artisanal, un accessoire indispensable pour tous les amateurs de thé. Conçu pour optimiser la libération des arômes, cet infuseur vous permet de profiter pleinement de chaque tasse de thé bio.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="coffee_img">
                      <image src="" alt="image d'illustration thé détox" />
                    </div>
                    <h3 className="types_text">Thé détox</h3>
                    <p className="looking_text">
                      Faites une pause bien-être avec notre thé détox bio, spécialement élaboré pour purifier et revitaliser votre corps. Composé d'ingrédients naturels et biologiques, ce thé détox est parfait pour ceux qui cherchent à allier plaisir et santé.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="coffee_img">
                      <image src="" alt="image d'illustration thé signature" />
                    </div>
                    <h3 className="types_text">Thé signature</h3>
                    <p className="looking_text">
                      Découvrez l'authenticité et le raffinement avec notre thé signature. Chaque mélange signature est le fruit de notre savoir-faire artisanal et de notre passion pour le thé bio.
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="coffee_img">
                      <image src="" alt="image d'illustration coffret découverte" />
                    </div>
                    <h3 className="types_text">Coffret découverte</h3>
                    <p className="looking_text">
                      Explorez l'univers riche et varié de Thé Tip Top avec notre coffret découverte. Ce coffret contient une sélection de nos meilleurs thés bios, y compris des thés détox, des infusions, et des mélanges signature.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            </Container>

        </section>

        {/* Participate Section */}
        <section id="participate" className="py-5" style={{ backgroundColor: '#FFFFFF' }}>
          <Container>
            <Row className="align-items-center">
              <Col lg={12} className="order-lg-1">
                <h2 className="display-4 mb-4">Laissez le thé vous guider !</h2>
                <ol className="lead">
                  <li className="mb-3">Faites un achat de 49€ ou plus dans l'une de nos boutiques ou sur notre site web.</li>
                  <li className="mb-3">Récupérez votre code unique sur votre ticket de caisse ou votre facture.</li>
                  <li className="mb-3">Inscrivez-vous sur notre site dédié au jeu-concours.</li>
                  <li className="mb-3">Entrez votre code et découvrez immédiatement votre gain.</li>
                  <li className="mb-3">Récupérez votre lot en boutique ou commandez-le en ligne.</li>
                </ol>
                <p className="font-italic">Vous avez 60 jours à partir du début du jeu pour participer et réclamer votre lot !</p>
                <Button variant="primary" size="lg" className="mt-3 rounded-pill" as={Link} to={Routes.Signin.path}>
                  Participer maintenant
                </Button>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Footer */}
        <footer className="py-5 text-white" style={{ background: 'linear-gradient(45deg, #2C3E50, #3498DB)' }}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col xs={12} md={8}>
                <ul className="list-inline mb-3">
                  {[
                    { path: Routes.TermsConditions.path, text: "Politique de confidentialité" },
                    { path: Routes.Cgv.path, text: "CGV" },
                    { path: Routes.Cgu.path, text: "CGU" }
                  ].map((link, index) => (
                      <li key={index} className="list-inline-item mx-2">
                        <Card.Link as={Link} to={link.path} className="text-white hover-opacity">
                          {link.text}
                        </Card.Link>
                      </li>
                  ))}
                </ul>
                <p className="small mb-0">
                  &copy; {currentYear} Thé Tip Top. Tous droits réservés.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
  );
};

export default LandingPage;